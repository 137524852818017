import request from "@/utils/request";

export const loginApi = (workNo, password) => {
  console.log(workNo, password);
  return request({
    url: "/api/admin/employee/login",
    method: "post",
    data: {
      workNo: workNo,
      password: password,
    },
  });
};


export const logoutApi = () => {
  return request.get("/api/admin/employee/logout");
};
