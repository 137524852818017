import request from '@/utils/request'

export const page =(currentPage,pageSize,name) => {
    return request.get('/api/admin/employee/page',{
        params: {
            currentPage,
            pageSize,
            name
        }
    })
}

export const add = (data) => {
    return request.post('/api/admin/employee/add',data)
}

export const selecetById = (id) => {
    return request({
        method: 'get',
        url: '/api/admin/employee/'+id
    })
}

export const updete = (data) => {
    return request.put('/api/admin/employee/update',data)
}


export const enableOrDisableEmployee = (id,status) => {
    return request({
      url: `/api/admin/employee/status/${status}`,
      method: 'post',
      params: { id }
    })
  }




