import request from '@/utils/request'

export const amountStatistics =(stateTime,endTime) => {
    return request.get('/api/report/amountStatistics',{
        params: {
            begin: stateTime,
            end: endTime
        }
    })
}


export const bookingStatistics =(stateTime,endTime) => {
    return request.get('/api/report/bookingStatistics',{
        params: {
            begin: stateTime,
            end: endTime
        }
    })
}