import request from '@/utils/request'

export const upload =(fromData) => {
    console.log(fromData.get('file'))
    return request({
        url: '/api/files/upload',
        method: 'post',
        data: fromData
        
    })
    
}