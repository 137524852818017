import "core-js/modules/es.array.push.js";
import { addService, getById, updateService } from '@/api/service';
import { upload } from '@/api/file';
import { ElMessage, ElMessageBox } from 'element-plus';
import { Plus } from '@element-plus/icons-vue';
export default {
  components: {
    Plus
  },
  data() {
    return {
      actionType: '',
      ruleForm: {
        type: '',
        description: '',
        price: '',
        imageUrl: ''
      },
      rules: {
        type: [{
          required: true,
          message: '请输入服务类型',
          trigger: 'blur'
        }],
        description: [{
          required: true,
          message: '请输入服务描述',
          trigger: 'blur'
        }],
        price: [{
          required: true,
          validator: (rules, value, callback) => {
            const reg = /^([1-9]\d{0,5}|0)(\.\d{1,2})?$/;
            if (!reg.test(value) || Number(value) <= 0) {
              callback(new Error('菜品价格格式有误，请输入大于零且最多保留两位小数的金额'));
            } else {
              callback();
            }
          },
          trigger: 'blur'
        }],
        imageUrl: [{
          required: true,
          message: '图片不能为空',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    UploadFile(param) {
      const formData = new FormData();
      formData.append('file', param.file);
      console.log(formData.get('file'));
      upload(formData).then(res => {
        if (res.data.code === 200) {
          this.ruleForm.imageUrl = res.data.data.imageUrl[0];
          ElMessage.success('上传成功');
        } else {
          ElMessage.error(res.data.message);
        }
      });
    },
    getByServiceId() {
      getById(this.$route.query.id).then(res => {
        if (res.data.code === 200) {
          this.ruleForm = res.data.data;
        } else {
          ElMessage.error(res.data.message);
        }
      });
    },
    onSubmit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.actionType === "add") {
            addService(this.ruleForm).then(res => {
              if (res.data.code === 200) {
                ElMessage.success(res.data.message);
                this.$router.push({
                  path: '/serrvices/index'
                });
              } else {
                ElMessage.error(res.data.message);
              }
            });
          } else if (this.actionType === "edit") {
            updateService(this.ruleForm).then(res => {
              if (res.data.code === 200) {
                ElMessage.success(res.data.message);
                this.$router.push({
                  path: '/serrvices/index'
                });
              } else {
                ElMessage.error(res.data.message);
              }
            });
          }
        } else {
          return false;
        }
      });
    }
  },
  created() {
    this.actionType = this.$route.query.id ? 'edit' : 'add';
    if (this.$route.query.id) {
      this.getByServiceId();
    }
  }
};