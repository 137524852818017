import request from '@/utils/request'


export const page =(currentPage,pageSize,name) => {
   return request.get('/api/admin/child/page',{
       params: {
           currentPage,
           pageSize,
           name
       }
   })
}
