import axios from 'axios'
import store from '@/store';

const request = axios.create({

})

request.interceptors.request.use(config => {
    if (config.url !== '/api/admin/employee/login') {
        if (store.getters.getUser.token) {
            config.headers['Authorization'] = store.getters.getUser.token
        }
    }
    return config;
}, error => {
    return Promise.reject(error);
});


export default request