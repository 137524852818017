import { page, add, updete, selecetById, enableOrDisableEmployee } from '@/api/employee';
import { ElMessage, ElMessageBox } from 'element-plus';
import { Search } from '@element-plus/icons-vue';
export default {
  data() {
    return {
      Search,
      title: '',
      dialogVisible: false,
      employeelist: [],
      name: '',
      data: {
        id: '',
        workNo: '',
        name: '',
        phone: '',
        sex: '',
        idNumber: '',
        status: '',
        createTime: '',
        updateTime: ''
      },
      currentPage: 1,
      pageSize: 10,
      total: 0,
      rules: {
        name: [{
          required: true,
          message: '请输入姓名名称',
          trigger: 'blur'
        }, {
          min: 2,
          max: 5,
          message: 'Length should be 2 to 5',
          trigger: 'blur'
        }],
        workNo: [{
          required: true,
          message: '请输入教职工号',
          trigger: 'blur'
        }, {
          pattern: /^[a-zA-Z0-9]{5,10}$/,
          message: "请输入正确的教职工号",
          trigger: "blur"
        }],
        phone: [{
          required: true,
          message: '请输入电话号码',
          trigger: 'blur'
        }, {
          pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
          message: "请输入正确的手机号码",
          trigger: "blur"
        }],
        idNumber: [{
          required: true,
          message: '请输入身份证号',
          trigger: 'blur'
        }, {
          pattern: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
          message: "请输入正确的身份证号",
          trigger: "blur"
        }],
        sex: [{
          required: true,
          message: '请选择性别',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    //分页
    getPage() {
      page(this.currentPage, this.pageSize).then(res => {
        console.log(res);
        if (res.data.code === 200) {
          this.employeelist = res.data.data.records;
          this.total = res.data.data.total;
        } else {
          ElMessage.error(res.data.message);
        }
      });
    },
    handleSizeChange(val) {
      this.getPage();
    },
    handleCurrentChange(val) {
      this.getPage();
    },
    currentIndex(index) {
      return (this.currentPage - 1) * this.pageSize + index + 1;
    },
    //根据条件查询分页
    getName() {
      page(this.currentPage, this.pageSize, this.name).then(res => {
        if (res.data.code === 200) {
          this.employeelist = res.data.data.records;
          this.total = res.data.data.total;
          this.name = '';
        } else {
          ElMessage.error(res.data.message);
        }
      });
    },
    sure(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.title === "添加数据") {
            this.addEmployee();
          } else if (this.title === "修改数据") {
            this.updeteEmployee();
          }
        } else {
          return false;
        }
      });
    },
    //添加
    addEmployee() {
      add(this.data).then(res => {
        if (res.data.code === 200) {
          ElMessage.success(res.data.message);
        } else {
          ElMessage.error(res.data.message);
        }
        this.getPage();
      });
      // this.getPage()
      this.dialogVisible = false;
    },
    add() {
      for (let key in this.data) {
        this.data[key] = '';
      }
      this.dialogVisible = true, this.title = "添加数据";
    },
    updete(row) {
      selecetById(row.id).then(res => {
        if (res.data.code === 200) {
          this.data = res.data.data;
        } else {
          ElMessage.error(res.data.message);
        }
      });
      this.title = "修改数据";
      this.dialogVisible = true;
    },
    updeteEmployee() {
      updete(this.data).then(res => {
        if (res.data.code === 200) {
          ElMessage.success(res.data.message);
        } else {
          ElMessage.error(res.data.message);
        }
        this.getPage();
      });
      this.dialogVisible = false;
    },
    statusHandle(row) {
      ElMessageBox.confirm('确认调整该账号的状?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        enableOrDisableEmployee(row.id, !row.status ? 1 : 0).then(res => {
          if (res.data.code === 200) {
            ElMessage.success(res.data.message);
          } else {
            ElMessage.error(res.data.message);
          }
          this.getPage();
        });
      }).catch(() => {
        ElMessage.info("操作已取消");
      });
    }
  },
  created() {
    this.getPage();
  }
};