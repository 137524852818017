import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-49ece724"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_Plus = _resolveComponent("Plus");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_el_upload = _resolveComponent("el-upload");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_form = _resolveComponent("el-form");
  return _openBlock(), _createBlock(_component_el_form, {
    model: $data.ruleForm,
    rules: $data.rules,
    ref: "ruleForm",
    "label-width": "auto",
    style: {
      "max-width": "400px"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_form_item, {
      label: "服务类型:",
      prop: "type"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: $data.ruleForm.type,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.ruleForm.type = $event),
        placeholder: "请填写服务类型"
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_form_item, {
      label: "服务描述:",
      prop: "description"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: $data.ruleForm.description,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.ruleForm.description = $event),
        type: "textarea",
        placeholder: "请填写服务描述"
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_form_item, {
      label: "价格:",
      prop: "price"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: $data.ruleForm.price,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.ruleForm.price = $event),
        placeholder: "请设置价格"
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_form_item, {
      label: "图片:",
      prop: "imageUrl"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_upload, {
        class: "avatar-uploader",
        action: "#",
        "show-file-list": false,
        "http-request": $options.UploadFile
      }, {
        default: _withCtx(() => [$data.ruleForm.imageUrl ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: $data.ruleForm.imageUrl,
          class: "avatar"
        }, null, 8, _hoisted_1)) : (_openBlock(), _createBlock(_component_el_icon, {
          key: 1,
          class: "avatar-uploader-icon"
        }, {
          default: _withCtx(() => [_createVNode(_component_Plus)]),
          _: 1
        }))]),
        _: 1
      }, 8, ["http-request"])]),
      _: 1
    }), _createVNode(_component_el_form_item, null, {
      default: _withCtx(() => [_createVNode(_component_el_button, {
        type: "primary",
        onClick: _cache[3] || (_cache[3] = $event => $options.onSubmit('ruleForm'))
      }, {
        default: _withCtx(() => [_createTextVNode("提交")]),
        _: 1
      }), _createVNode(_component_el_button, {
        onClick: _cache[4] || (_cache[4] = () => _ctx.$router.back())
      }, {
        default: _withCtx(() => [_createTextVNode("取消")]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }, 8, ["model", "rules"]);
}