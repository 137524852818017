import request from '@/utils/request'

export const page =(currentPage,pageSize,type) => {
    return request.get('/api/admin/services/page',{
        params: {
            currentPage,
            pageSize,
            type
        }
    })
}

export const addService =(ruleForm) => {
    console.log(ruleForm)
    return request({
        url: '/api/admin/services/add',
        method: 'post',
        data: ruleForm
        
        
    })
}

export const getById =(servicesId) => {
    return request({
        url: '/api/admin/services/getById',
        method: 'get',
        params: {
            id: servicesId
        }
        
        
    })
}

export const updateService =(ruleForm) => {
    console.log(ruleForm)
    return request({
        url: '/api/admin/services/update',
        method: 'post',
        data: ruleForm
        
        
    })
}

export const deleteService =(servicesId) => {
    return request({
        url: '/api/admin/services/delete',
        method: 'get',
        params: {
            id: servicesId
        }
        
        
    })
}