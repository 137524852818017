import request from '@/utils/request'

export const page =(currentPage,pageSize,userName) => {
    return request.get('/api/admin/bookings/page',{
        params: {
            currentPage,
            pageSize,
            userName
        }
    })
}