import "core-js/modules/es.array.push.js";
import router from '@/router';
import store from '@/store';
import { logoutApi } from '@/api/user';
import { Management, UserFilled, CaretBottom, Tickets, Fold, ShoppingBag, House } from '@element-plus/icons-vue';
import avatar from '@/assets/OIP.jpg';
import { ElMessage, ElNotification } from 'element-plus';
export default {
  components: {
    Management,
    UserFilled,
    CaretBottom,
    Tickets,
    Fold,
    ShoppingBag,
    House
  },
  data() {
    return {
      avatar,
      userName: '',
      websocket: null
    };
  },
  methods: {
    handleCommand(command) {
      switch (command) {
        case "登录":
          router.push({
            path: '/login'
          });
          break;
        case "退出登录":
          logoutApi().then(res => {
            ElMessage.success(res.data.msg);
          });
          router.push({
            path: '/login'
          });
          window.sessionStorage.clear();
          location.reload();
          break;
        default:
          break;
      }
    },
    webSocket() {
      const that = this;
      let clientId = store.getters.getUser.id;
      let socketUrl = 'ws://hqh.asia:8084/websocket/' + clientId;
      console.log(socketUrl, 'socketUrl');
      if (typeof WebSocket == 'undefined') {
        ElNotification({
          title: '提示',
          message: '当前浏览器无法接收实时报警信息，请使用谷歌浏览器',
          type: 'warning'
        });
      } else {
        this.websocket = new WebSocket(socketUrl);
        // 监听socket打开
        this.websocket.onopen = function () {
          console.log('浏览器WebSocket已打开');
        };
        // 监听socket消息接收
        this.websocket.onmessage = function (msg) {
          // 转换为json对象
          // that.$refs.audioVo.currentTime = 0
          // that.$refs.audioVo2.currentTime = 0

          console.log(msg, msg.data, 'msg');
          // const h = this.$createElement
          //const jsonMsg = JSON.parse(msg.data)
          // if (jsonMsg.type === 1) {
          //   that.$refs.audioVo.play()
          // } else if (jsonMsg.type === 2) {
          //   that.$refs.audioVo2.play()
          // }
          ElNotification({
            title: '预订提醒',
            duration: 0,
            dangerouslyUseHTMLString: true,
            // 这里也可以把返回信息加入到message中显示
            message: `${`<span>您有1个<span style=color:#419EFF>新的预订信息</span>,${msg.data},请及时处理</span>`}`
          });
        };
        // 监听socket错误
        this.websocket.onerror = function () {
          ElNotification({
            title: '错误',
            message: '服务器错误，无法接收实时报警信',
            type: 'error'
          });
        };
        // 监听socket关闭
        this.websocket.onclose = function () {
          console.log('WebSocket已关闭');
        };
      }
    }
  },
  created() {
    this.userName = store.getters.getUser.name;
    this.webSocket();
  }
};