import { page } from '@/api/bookings';
import { ElMessage, ElMessageBox } from 'element-plus';
import { Search } from '@element-plus/icons-vue';
export default {
  data() {
    return {
      Search,
      loading: true,
      bookingslist: [],
      userName: '',
      currentPage: 1,
      pageSize: 10,
      total: 0
    };
  },
  methods: {
    //分页
    getPage() {
      page(this.currentPage, this.pageSize, this.userName).then(res => {
        console.log(res);
        if (res.data.code === 200) {
          this.bookingslist = res.data.data.records;
          this.total = res.data.data.total;
          this.loading = false;
          this.userName = '';
        } else {
          ElMessage.error(res.data.message);
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getPage();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getPage();
    },
    currentIndex(index) {
      return (this.currentPage - 1) * this.pageSize + index + 1;
    }
  },
  created() {
    this.getPage();
  }
};