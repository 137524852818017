import { createStore } from 'vuex'
import VuexPersistedState from 'vuex-persistedstate'

export default createStore({
  plugins: [new VuexPersistedState({
    storage: window.sessionStorage
  })],
  state: {
    user: null
  },
  getters: {
    getUser(state) {
      return state.user
    }
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user
    }
  },
  actions: {
    setUser({ commit }, user) {
      commit('SET_USER', user)
    }
  },
  modules: {
  }
})
