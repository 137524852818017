import "core-js/modules/es.array.push.js";
import { page, deleteService } from '@/api/service';
import { ElMessage, ElMessageBox } from 'element-plus';
import { Search } from '@element-plus/icons-vue';
export default {
  data() {
    return {
      Search,
      loading: true,
      servicelist: [],
      type: '',
      currentPage: 1,
      pageSize: 10,
      total: 0
    };
  },
  methods: {
    //分页
    getPage() {
      page(this.currentPage, this.pageSize, this.type).then(res => {
        console.log(res);
        if (res.data.code === 200) {
          this.servicelist = res.data.data.records;
          this.total = res.data.data.total;
          this.loading = false;
          this.type = '';
        } else {
          ElMessage.error(res.data.message);
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getPage();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getPage();
    },
    currentIndex(index) {
      return (this.currentPage - 1) * this.pageSize + index + 1;
    },
    add(st) {
      if (st === 'add') {
        this.$router.push({
          path: '/serrvices/addServices'
        });
      } else {
        this.$router.push({
          path: '/serrvices/addServices',
          query: {
            id: st
          }
        });
      }
    },
    Deleter(id) {
      ElMessageBox.confirm('此操作将永久删除该条数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteService(id).then(res => {
          if (res.data.code === 200) {
            ElMessage.success(res.data.message);
            this.getPage();
          } else {
            ElMessage.error(res.data.message);
          }
        });
      }).catch(() => {
        ElMessage.info("删除已取消");
      });
    }
  },
  created() {
    this.getPage();
  }
};