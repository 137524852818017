import * as echarts from 'echarts';
import moment from 'moment';
import { amountStatistics, bookingStatistics } from '@/api/report';

import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  data() {
    return {
      stateTime: '',
      endTime: '',
      dataTime: '',
      xAxisData: [],
      seriesData: [],
      seriesData1: [],
      totalAmount: ''
    };
  },
  methods: {
    myEchart() {
      var chartDom = document.getElementById('main');
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        title: {
          text: '每日收入金额数据',
          x: 'center',
          y: 'bottom',
          textStyle: {
            fontSize: 14
          }
        },
        grid: {
          left: '3%',
          right: '7%',
          bottom: '7%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            xAxis: 'auto'
          }
        },
        xAxis: {
          name: '日期',
          type: 'category',
          data: this.xAxisData,
          axisLabel: {
            inside: false,
            rotate: 45
          },
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          name: '金额/元'
        },
        series: [{
          name: '收入',
          data: this.seriesData,
          type: 'bar'
        }]
      };
      option && myChart.setOption(option);
    },
    myEchart1() {
      var chartDom = document.getElementById('main1');
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        title: {
          text: '订单类别比',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          bottom: 10,
          left: 'center'
        },
        series: [{
          name: 'Access From',
          type: 'pie',
          radius: '50%',
          data: this.seriesData1,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }]
      };
      option && myChart.setOption(option);
    },
    changeDate() {
      this.stateTime = moment(this.dataTime[0]).format('YYYY-MM-DD');
      this.endTime = moment(this.dataTime[1]).format('YYYY-MM-DD');
      this.getamountStatistics(this.stateTime, this.endTime);
      this.getbookingStatistics(this.stateTime, this.endTime);
    },
    getamountStatistics(stateTime, endTime) {
      amountStatistics(stateTime, endTime).then(res => {
        if (res.data.code === 200) {
          this.xAxisData = res.data.data.dateList;
          this.seriesData = res.data.data.amountList;
          this.totalAmount = res.data.data.totalAmount;
          this.myEchart();
        } else {
          ElMessage.error(res.data.message);
        }
      });
    },
    getbookingStatistics(stateTime, endTime) {
      bookingStatistics(stateTime, endTime).then(res => {
        if (res.data.code === 200) {
          this.seriesData1 = res.data.data;
          this.myEchart1();
        } else {
          ElMessage.error(res.data.message);
        }
      });
    }
  },
  mounted() {
    this.myEchart();
    this.myEchart1();
  },
  created() {
    this.stateTime = moment().add(-7, 'day').format('YYYY-MM-DD');
    this.endTime = moment().format('YYYY-MM-DD');
    this.getamountStatistics(this.stateTime, this.endTime);
    this.getbookingStatistics(this.stateTime, this.endTime);
  }
};