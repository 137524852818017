import { page } from '@/api/child';
import { ElMessage, ElMessageBox } from 'element-plus';
export default {
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      total: 0,
      childList: [],
      name: ''
    };
  },
  methods: {
    getPage() {
      page(this.currentPage, this.pageSize).then(res => {
        if (res.data.code === 200) {
          this.childList = res.data.data.records;
          this.total = res.data.data.total;
        } else {
          ElMessage.error(res.data.message);
        }
      });
    },
    handleSizeChange(val) {
      this.getPage();
    },
    handleCurrentChange(val) {
      this.getPage();
    },
    currentIndex(index) {
      return (this.currentPage - 1) * this.pageSize + index + 1;
    },
    //根据条件查询分页
    getChildName() {
      page(this.currentPage, this.pageSize, this.name).then(res => {
        if (res.data.code === 200) {
          this.childList = res.data.data.records;
          this.total = res.data.data.total;
          this.name = '';
        } else {
          ElMessage.error(res.data.message);
        }
      });
    }
  },
  created() {
    this.getPage();
  }
};