import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-98cd3a5e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "head"
};
const _hoisted_2 = {
  class: "boyd"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_table_column = _resolveComponent("el-table-column");
  const _component_el_table = _resolveComponent("el-table");
  const _component_el_pagination = _resolveComponent("el-pagination");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_input, {
    type: "text",
    modelValue: $data.name,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.name = $event),
    placeholder: "输入孩子名字",
    style: {
      "width": "200px"
    }
  }, null, 8, ["modelValue"]), _createVNode(_component_el_button, {
    type: "primary",
    onClick: $options.getChildName,
    style: {
      "margin-left": "10px"
    }
  }, {
    default: _withCtx(() => [_createTextVNode("查询")]),
    _: 1
  }, 8, ["onClick"])]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_el_table, {
    data: $data.childList,
    stripe: "",
    "header-cell-style": {
      textAlign: 'center'
    },
    "cell-style": {
      textAlign: 'center'
    },
    style: {
      "width": "100%"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_table_column, {
      type: "index",
      index: $options.currentIndex,
      label: "序号",
      width: "60"
    }, null, 8, ["index"]), _createVNode(_component_el_table_column, {
      prop: "name",
      label: "姓名",
      width: "90"
    }), _createVNode(_component_el_table_column, {
      prop: "sex",
      label: "性别",
      width: "60"
    }, {
      default: _withCtx(scope => [_createTextVNode(_toDisplayString(scope.row.sex === 1 ? '男' : '女'), 1)]),
      _: 1
    }), _createVNode(_component_el_table_column, {
      prop: "birthday",
      label: "生日",
      width: "180"
    }), _createVNode(_component_el_table_column, {
      prop: "medicalInformation",
      label: "医疗信息",
      width: "180"
    }), _createVNode(_component_el_table_column, {
      prop: "emergencyContact",
      label: "紧急联系人",
      width: "120"
    }), _createVNode(_component_el_table_column, {
      prop: "phone",
      label: "紧急联系人电话",
      width: "150"
    }), _createVNode(_component_el_table_column, {
      prop: "createTime",
      label: "创建时间",
      width: "170"
    }), _createVNode(_component_el_table_column, {
      prop: "updateTime",
      label: "更新时间",
      width: "170"
    })]),
    _: 1
  }, 8, ["data"])]), _createElementVNode("div", null, [_createVNode(_component_el_pagination, {
    "current-page": $data.currentPage,
    "onUpdate:currentPage": _cache[1] || (_cache[1] = $event => $data.currentPage = $event),
    "page-size": $data.pageSize,
    "onUpdate:pageSize": _cache[2] || (_cache[2] = $event => $data.pageSize = $event),
    "page-sizes": [5, 10, 15],
    background: "",
    layout: "->,total, sizes, prev, pager, next, jumper",
    total: $data.total,
    onSizeChange: $options.handleSizeChange,
    onCurrentChange: $options.handleCurrentChange
  }, null, 8, ["current-page", "page-size", "total", "onSizeChange", "onCurrentChange"])])]);
}