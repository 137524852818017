import { createRouter, createWebHashHistory } from 'vue-router'
import LayoutContainer from '@/views/LayoutContainer.vue'
import login from '@/views/login.vue'
import employee from '@/views/employee/index'
import child from '@/views/child/index'
import service from '@/views/serrvices/index'
import addService from '@/views/serrvices/addServices'
import bookings from '@/views/bookings/index'
import echarts from '@/views/echarts/index'

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/:catchAll(.*)',component: () => import('@/views/404.vue')
    },
    {
      path: '/login',component: login
    },
  
    {
      path: '/layout',
      component: LayoutContainer,
      redirect: '/echarts/index',
      children: [
        {
          path: '/employee/index',component: employee
        },
        {
          path: '/child/index',component: child
        },
        {
          path: '/serrvices/index',component: service
        },
        {
          path: '/serrvices/addServices',
          component: addService,
          meta: {
            title: "添加服务",
            hidden: true
          }
        },
        {
          path: '/bookings/index',component: bookings
        },
        {
          path: '/echarts/index',component: echarts
        }
      ]
    }
  ]
})

export default router
