import "core-js/modules/es.array.push.js";
import { loginApi } from '@/api/user';
import { ElMessage } from 'element-plus';
import { User, Lock } from '@element-plus/icons-vue';
export default {
  data() {
    return {
      User,
      Lock,
      loginFrom: {
        workNo: '24000001',
        password: '123456'
      },
      rules: {
        workNo: [{
          required: true,
          message: '请输入职工号',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          console.log(this.loginFrom.workNo, this.loginFrom.password);
          loginApi(this.loginFrom.workNo, this.loginFrom.password).then(res => {
            console.log(res);
            if (res.data.code === 200) {
              ElMessage.success('登录成功');
              this.$store.dispatch('setUser', res.data.data);
              console.log("yyyyyyy");
              console.log(this.$store.getters.getUser);
              this.$router.push("/layout");
            } else {
              ElMessage.error(res.data.message);
            }
          });
        } else {
          ElMessage.error("请输入账号和密码");
          return false;
        }
      });
    }
  }
};